import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import { Heading3 } from '../../atoms/Headings/Headings';
import sanityImage from '../../../utils/sanityImage';
import Card from '../../atoms/Card/Card';
import Text from '../../atoms/Text/Text';
import Image from '../../atoms/Image/Image';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';
import Section from '../../molecules/Section/Section';

const quoteMarksPng = '/quotes/blue-quote-marks.png';

const QuoteGradientCard = ({
  authorTitle,
  clientImage,
  clientQuote,
  quoteAuthor,
  sectionStyles,
  suiteType,
}) => {
  const quoteGradientCardCss = css`
    width: 100%;
    max-width: ${pageDimensions.desktopWidth};
    margin: 0 auto;
    padding: ${uc('75px 100px 80px')};
    text-align: center;
    background-image: linear-gradient(
      to right,
      ${colors[suiteType].gradient.start},
      ${colors[suiteType].gradient.end}
    );

    @media (${breakpoints.smallDesktopMin}) {
      width: 95%;
    }

    @media (${breakpoints.tablet}) {
      margin-top: ${uc('80px')};
    }

    @media (${breakpoints.mobile}) {
      width: 95%;
      padding: ${uc('40px')};
    }
  `;

  const clientImageCss = css`
    border: ${uc('7px')} solid transparent;
    border-radius: 50%;
  `;

  const clientImageBorderCss = css`
    position: absolute;
    left: 50%;
    text-align: center;
    background-image: linear-gradient(
      to right,
      ${colors[suiteType].gradient.start},
      ${colors[suiteType].gradient.end}
    );
    border-radius: 50%;

    /* translate it center and on top of the block */
    transform: translate(-50%, -100%);

    &::after {
      position: absolute;
      right: -${uc('9px')};
      bottom: -${uc('9px')};
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${uc('60px')};
      height: ${uc('60px')};
      background: ${colors.white};
      border-radius: 50%;
      transform: scale(0.9);
      content: url(${quoteMarksPng});
    }
  `;

  const heading3Css = css`
    margin-top: ${uc('24px')};
    margin-bottom: 0;
  `;

  const textCss = css`
    color: ${colors.white};
    font-weight: ${fontWeights.medium};
    font-size: ${fontSizes.twentyThree};
    font-family: ${fontFamilies.roboto};

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.sixteen};
    }
  `;

  const clientQuoteCss = css`
    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.sixteen};
    }
  `;

  return (
    <Section sectionStyles={sectionStyles}>
      <Card css={quoteGradientCardCss}>
        {clientImage.asset && (
          <div css={clientImageBorderCss}>
            <Image
              css={clientImageCss}
              src={sanityImage(clientImage)
                .auto('format')
                .width(140)
                .url()}
            />
          </div>
        )}
        <Heading3 color={colors.white} css={heading3Css}>
          {quoteAuthor}
        </Heading3>
        <Text css={textCss}>{authorTitle}</Text>
        <Heading3 css={clientQuoteCss} color={colors.white}>
          {clientQuote}
        </Heading3>
      </Card>
    </Section>
  );
};

QuoteGradientCard.propTypes = {
  suiteType: PropTypes.string.isRequired,
  clientImage: PropTypes.shape({
    asset: {},
  }),
  quoteAuthor: PropTypes.string,
  authorTitle: PropTypes.string,
  clientQuote: PropTypes.string,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
};

QuoteGradientCard.defaultProps = {
  clientImage: null,
  quoteAuthor: '',
  authorTitle: '',
  clientQuote: '',
  sectionStyles: {},
};

export default QuoteGradientCard;
